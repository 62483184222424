@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes cursor {
  from {
    border-right-color: rgba(0, 0, 0, 0.75);
  }
  to {
    border-right-color: transparent;
  }
}

.typing-text {
  display: inline-block;
  white-space: nowrap;
  border-right: 3px solid;
  animation: typing 5s steps(40, end) 1s forwards,
    cursor 5s steps(40, end) forwards;
}

.typing-text::after {
  content: "|";
  animation: cursor 5s steps(40, end) forwards;
}
